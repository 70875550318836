





















































































































import { Observer } from 'mobx-vue'
import { Component, Vue, Inject } from 'vue-property-decorator'
import { walletStore } from '@/stores/wallet-store'
import { MintingViewModel } from '../viewmodels/minting-viewmodel'

@Observer
@Component({
  components: {
    'custom-progress-bar': () => import('./custom-progress-bar.vue'),
    'mint-input-task': () => import('./mint-input-task.vue'),
    'minting-connect-button': () => import('./minting-connect-button.vue'),
  },
})
export default class PhaseNftInfoCard extends Vue {
  @Inject() providers
  walletStore = walletStore
  @Inject({}) vm!: MintingViewModel
  tab = 1
  destroyed() {
    //
  }
}
